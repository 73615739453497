import React from "react";
import "./testimonials.css";
import {Card, Col, Container, Row} from "react-bootstrap";
import ReactOwlCarousel from "react-owl-carousel";
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';


function Testimonials(props) {
    const colors = ['red', 'cyan', 'blue', 'orange'];

    return (
        <Container className="testimonial-container">
            <Row className="justify-content-center">
                <Col className="text-center">
                    <h3 className="my-3 left-heading">Listen to what our customers have to say</h3>
                </Col>
            </Row>

            <ReactOwlCarousel className="owl-theme testimonial-carousel"
                              items={1}
                              loop autoplay
                              margin={4}
            >
                {
                    props.data.map((customer, index) => (
                        <div className={"item " + colors[index % colors.length]} key={index + "_" + customer.name}>
                            <Card className="card-shadow border-0 mb-4">
                                <Card.Body>
                                    <div
                                        className="position-relative thumb bg-success-gradiant d-inline-block text-black-50 fw-bolder mb-4">
                                        <img
                                            src={customer.image === '' ? 'https://static.vecteezy.com/system/resources/thumbnails/003/337/584/small/default-avatar-photo-placeholder-profile-icon-vector.jpg' : customer.image}
                                            alt={customer.name === '' ? "Anonymous" : customer.name}
                                            className="thumb-img position-absolute rounded-circle"/>
                                        {customer.name === '' ? "Anonymous" : customer.name}
                                    </div>
                                    <h5 className="font-weight-light">{customer.statement}</h5>
                                    <span className="divider d-inline-block my-3"/>
                                    <h6 className="font-weight-normal">{customer.title}</h6>
                                </Card.Body>
                            </Card>
                        </div>
                    ))
                }
            </ReactOwlCarousel>
        </Container>
    );
}

export default Testimonials;