import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import {BrowserRouter as Router, Route, Routes} from "react-router-dom";
import Home from './Home';
import reportWebVitals from './reportWebVitals';
import Footer from "./commons/footer/footer";
import Navigation from "./commons/navigation/navigation";
import About from "./about/about";
import Contact from "./contact/contact";
import Product from "./products/product";

ReactDOM.render(
    <Router>
        <Navigation/>
        <Routes>
            <Route path="/" element={<Home/>}/>
            <Route path="/about" element={<About/>}/>
            <Route path="/contact" element={<Contact/>}/>
            <Route path="/products" element={<Product/>}>
                <Route path="" element={<Product/>}/>
                {/*<Route path=":productSlug" element={<Product />} />*/}
            </Route>
        </Routes>
        <Footer/>
    </Router>,
    document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
