import React from "react";
import './Home.css';
import {Col, Container, Row} from "react-bootstrap";
import {FaEye, FaRocket} from "react-icons/fa";
import Fade from 'react-reveal/Fade';
import Testimonials from "./commons/testimonials/testimonials";
import testimonials from "./data/testimonials";

function Home() {
    return (
        <>
            <section className="landing">
                <Fade top>
                    <h1 className="text-center main-heading">The Iris</h1>
                    <h4 className="text-center sub-heading">Curiosity Kept Alive</h4>
                </Fade>
            </section>

            <section className="statements">
                <Container>
                    <Row className="mb-5">
                        <p className="text-opacity-50">THE IRIS is a startup that took its initial steps in 2019 with
                            the help of a
                            grant from the Govt of Karnataka with a mission to create innovative educational
                            products to enrich the learning experience of a student.</p>
                    </Row>
                    <Row>
                        <Col>
                            <h3 className="text-center">
                                <FaEye/>
                                <br/>
                                <span>Vision</span>
                            </h3>
                            <p className="text-center mt-4">
                                To radically shift the learning culture towards positive growth that we
                                believe will happen only with inspirational learning and asking ‘WHY’
                            </p>
                        </Col>

                        <Col>
                            <h3 className="text-center">
                                <FaRocket/>
                                <br/>
                                <span>Mission</span>
                            </h3>
                            <p className="text-center mt-4">
                                Innovate and strive to offer educational tools that can instill
                                practical knowledge to satisfy curiosity with a good learning
                                experience for the ‘future’ of our world
                            </p>
                        </Col>
                    </Row>
                </Container>
            </section>

            <section className="product-intro">
                <Container>
                    <h1 className="text-center main-heading left-heading">The Pocket Microscope</h1>
                    <Row className="mt-5">
                        <Col className="video-container">
                            <iframe
                                width="100%"
                                height="600"
                                src="https://www.youtube.com/embed/7jSDZJr18Ak"
                                title="The Iris Pocket Microscope"
                                frameBorder="0"
                                allow="accelerometer;
                                autoplay; clipboard-write;
                                encrypted-media; gyroscope;
                                picture-in-picture"
                                allowFullScreen/>
                        </Col>
                    </Row>
                </Container>
            </section>

            <section className="testimonials">
                <Testimonials
                    data={testimonials}/>
            </section>
        </>
    );
}

export default Home;
