import React from "react";
import "./footer.css";
import {AiTwotoneHeart} from "react-icons/ai";
import {Col, Container, Row} from "react-bootstrap";
import {FaEnvelope, FaFacebook, FaInstagram, FaLinkedin, FaMapPin, FaPhone, FaYoutubeSquare} from "react-icons/fa";
import {Link} from "react-router-dom";

function Footer() {
    return (
        <footer className="new_footer_area bg_color">
            <div className="new_footer_top">
                <div className="container">
                    <Row>
                        <Col>
                            <div className="f_widget company_widget wow fadeInLeft" data-wow-delay="0.2s"
                                 style={{visibility: 'visible', animationDelay: '0.2s', animationName: 'fadeInLeft'}}>
                                <h3 className="f-title f_600 t_color f_size_18">Get in Touch</h3>
                                <p>Don’t miss any updates of our new products and releases.!</p>
                                <form action="#" className="f_subscribe_two mailchimp">
                                    <input type="text" name="EMAIL" className="form-control memail"
                                           placeholder="Email"/>
                                    <button className="btn btn_get btn_get_two" type="submit">Subscribe</button>
                                    <p className="mchimp-errmessage" style={{display: 'none'}}/>
                                    <p className="mchimp-sucmessage" style={{display: 'none'}}/>
                                </form>
                            </div>
                        </Col>

                        <Col>
                            <div className="f_widget about-widget pl_70 wow fadeInLeft" data-wow-delay="0.6s"
                                 style={{visibility: 'visible', animationDelay: '0.6s', animationName: 'fadeInLeft'}}>
                                <h3 className="f-title f_600 t_color f_size_18">Help</h3>
                                <ul className="list-unstyled f_list">
                                    <li><Link to="/">FAQ</Link></li>
                                    <li><Link to="/">Term &amp; conditions</Link></li>
                                    <li><Link to="/">Documentation</Link></li>
                                    <li><Link to="/">Support Policy</Link></li>
                                    <li><Link to="/">Privacy</Link></li>
                                </ul>
                            </div>
                        </Col>

                        <Col>
                            <Row className="f_widget social-widget pl_70 wow fadeInLeft" data-wow-delay="0.8s"
                                 style={{visibility: 'visible', animationDelay: '0.8s', animationName: 'fadeInLeft'}}>
                                <h3 className="f-title f_600 t_color f_size_18">Connect</h3>
                                <div className="f_social_icon">
                                    <FaFacebook/>
                                    <FaYoutubeSquare href="https://www.youtube.com/channel/UCeZb7Pq8WvR84VxEojN6TGA"/>
                                    <FaLinkedin/>
                                    <FaInstagram href="https://instagram.com/the_iris.in" target="_blank"/>
                                </div>
                            </Row>

                            <Row>
                                <ul className="list-unstyled f_list" style={{'color': '#6a7695'}}>
                                    <li><FaMapPin className="m-1"/>229/1, Oil mill road,
                                        Lingarajpuram, Bangalore
                                    </li>
                                    <li><FaPhone className="m-1"/><a style={{"color": "#6a7695"}}
                                                                     href="tel:+91 8050003572">+91 8050003572</a></li>
                                    <li><FaEnvelope className="m-1"/><a style={{"color": "#6a7695"}}
                                                                        href="mailto:info@theiris.co.in">info@theiris.co.in</a>
                                    </li>
                                </ul>
                            </Row>
                        </Col>
                    </Row>
                </div>
                <div className="footer_bg">
                    <div className="footer_bg_one"/>
                    <div className="footer_bg_two"/>
                </div>
            </div>
            <div className="footer_bottom">
                <Container>
                    <div className="row align-items-center">
                        <div className="col-lg-6 col-sm-7">
                            <p className="mb-0 f_400">&copy; The Iris {new Date().getFullYear()} All Rights
                                Reserved.</p>
                        </div>
                        <div className="col-lg-6 col-sm-5 text-right">
                            <p>Developed with <AiTwotoneHeart color='red'/> by <a
                                href="https://rifasm.github.io">PingIO</a></p>
                        </div>
                    </div>
                </Container>
            </div>
        </footer>
    );
}

export default Footer;