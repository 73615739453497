import Fade from "react-reveal/Fade";
import React from "react";
import './products.css';
import ReactOwlCarousel from "react-owl-carousel";
import {Col, Container, Row} from "react-bootstrap";

function Product() {
    const productRef = React.createRef();

    const accessories = [
        {image: 'pouch', name: "Go anywhere Pouch"},
        {image: 'led', name: "LED for Illumination"},
        {image: 'sample-slides', name: "The Iris Standard Slides"},
    ];

    const landing_items = [
        {image: 'microscope', name: ""},
    ].concat(accessories);

    const product_items = landing_items.concat([
        {image: 'product', name: "Poster"},
        {image: 'product-elaboration', name: "Elaboration"},
        {image: 'product1', name: "Poster"},
        {image: 'product-positives', name: "Benefits"},
    ]);

    const colors = ['red', 'cyan', 'blue', 'orange'];

    function carousel_builder(no_items_to_display, items, responsive) {
        return (
            <ReactOwlCarousel items={no_items_to_display} className="owl-theme"
                              loop autoplay={responsive}
                              autoplaySpeed={3200}
                              autoplayTimeout={3200}
                              autoplayHoverPause={responsive}
                              dots={!responsive}
                              responsive={
                                  responsive ?
                                      {
                                          0: {
                                              items: 1
                                          },
                                          600: {
                                              items: 2
                                          },
                                          1000: {
                                              items: 3
                                          }
                                      }
                                      :
                                      {}
                              }
                              margin={4}
            >{
                items.map((item, index) => {
                    return (
                        <img key={index} className="item" src={"/images/product/" + item.image + ".jpg"}
                             alt={"Pocket Microscope " + item.name}/>
                    )
                })
            }
            </ReactOwlCarousel>
        );
    }

    return (
        <>
            <section className="product-landing">
                <Fade top>
                    <h1 className="text-center main-heading">The most affordable microscope is here</h1>
                    <h4 className="text-center sub-heading">Get yours now</h4>
                    <button className="buy-button fw-bold"
                            onClick={() => productRef.current.scrollIntoView()}>Buy Now
                    </button>
                </Fade>

                {
                    carousel_builder(3, landing_items, true)
                }
            </section>

            <section ref={productRef} className="product-section">
                <Container>
                    <h1 className="text-xl-center">Pocket Microscope</h1>
                    <Row className="mt-5">
                        <Col lg={6} md={6}>
                            {
                                carousel_builder(1, product_items, false)
                            }
                        </Col>
                        <Col className="ml-4">
                            <h4>Each Kit Contains</h4>
                            <ul>
                                <li>Pocket Microscope</li>
                                <li>Lab Manual</li>
                                <li>10 Blank Nifty Slides</li>
                                <li>3 Nifty Slides with specimen</li>
                                <li>LED Light source for illumination</li>
                                <li>Water Resistant Pouch</li>
                                <li>Magic Tape (Washable and Reusable)</li>
                                <li>Notebook</li>
                                <li>Bookmark</li>
                            </ul>

                            <button
                                onClick={() => window.open('https://dl.flipkart.com/dl/product/p/itme?pid=ETYGFTNQQPB4HHAM&lid=LSTETYGFTNQQPB4HHAMOMMP20', '_blank')}
                                className="buy-button">Buy Now
                            </button>
                        </Col>
                    </Row>
                </Container>
            </section>

            <section className="product-accessories">
                <Container>
                    <h3 className="text-center fw-bold">Accessories</h3>

                    <Row className="mt-5">
                        {
                            accessories.map((item, index) => {
                                return (
                                    <Col lg={3} className={"accessory-cards box " + colors[index % colors.length]}
                                         key={index}
                                         onClick={() => window.open(item.link, '_blank')}
                                    >
                                        <h4 className="text-center fw-lighter">{item.name}</h4>
                                        <img className="img-fluid" src={"/images/product/" + item.image + ".jpg"}
                                             alt={"Pocket Microscope " + item.name}/>
                                        <button className="buy-button fw-bold mt-3"
                                                onClick={() => window.open(item.link, '_blank')}>Buy Now
                                        </button>
                                    </Col>
                                )
                            })
                        }
                    </Row>
                </Container>
            </section>

            <section>
                <Container>
                    <h3 className="text-center fw-bold">How to Use?</h3>

                    <Row className="mt-5">
                        <Col className="video-container">
                            <iframe
                                width="100%"
                                height="600"
                                src="https://www.youtube.com/embed/7jSDZJr18Ak"
                                title="The Iris Pocket Microscope"
                                frameBorder="0"
                                allow="accelerometer;
                                autoplay; clipboard-write;
                                encrypted-media; gyroscope;
                                picture-in-picture"
                                allowFullScreen/>
                        </Col>
                    </Row>
                </Container>
            </section>

            {/*<section>*/}
            {/*    FAQ*/}
            {/*</section>*/}

            {/*<section>*/}
            {/*    Material description*/}
            {/*</section>*/}
        </>
    );
}

export default Product;