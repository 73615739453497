import React from "react";
import "./contact.css";
import {Container, Row} from "react-bootstrap";
import {FaEnvelope, FaMapPin, FaPhone} from "react-icons/fa";

function Contact() {

    return (
        <>
            <section className="contact-header">
                <Container>
                    <Row>
                        <h1 className="text-center">Get In Touch</h1>
                    </Row>

                    <Row>
                        <div className="row1-container">
                            <div className="box box-down cyan">
                                <h2>Phone</h2>
                                <p>
                                    <a href="tel:+918050003572">+91 80500 03572</a>
                                    <br/>
                                    <a href="tel:+917760985868">+91 77609 85868</a>
                                </p>
                                <FaPhone size={50} color="hsl(180, 62%, 55%)"/>
                            </div>

                            <div className="box red">
                                <h2>Email</h2>
                                <p>
                                    <a href="mailto:info@theiris.co.in">info@theiris.co.in</a>
                                </p>
                                <FaEnvelope size={50} color="hsl(0, 78%, 62%)"/>
                            </div>

                            <div className="box box-down blue">
                                <h2>Address</h2>
                                <p>
                                    229/1, Oil Mill Road,
                                    Lingarajpuram,
                                    Bangalore - 560 084
                                </p>
                                <FaMapPin size={50} color="hsl(212, 86%, 64%)"/>
                            </div>
                        </div>
                    </Row>

                    <Row className="contact-map">
                        <h2>Locate Us</h2>
                        <p>
                            <iframe
                                title={'The Iris Location'}
                                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d971.8400672667547!2d77.63390602548127!3d13.012714710094604!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bae16df137659ab%3A0x24b6930292f684ef!2s229%2C%201%2C%20Oil%20Mill%20Rd%2C%20KSFC%20Layout%2C%20Lingarajapuram%2C%20Bengaluru%2C%20Karnataka%20560084!5e0!3m2!1sen!2sin!4v1642435171879!5m2!1sen!2sin"
                                width="100%"
                                height="450"
                                frameBorder="0"
                                style={{border: 0}}
                                allowFullScreen=""
                                aria-hidden={false}
                                tabIndex={0}
                                loading="lazy"
                            />
                        </p>
                    </Row>

                </Container>
            </section>
        </>
    );
}

export default Contact;