import {Col, Container, Row} from "react-bootstrap";
import ReactCanvasConfetti from "react-canvas-confetti";
import React from "react";
import "./about.css";

function About() {
    function teamCard(name, description) {
        return (
            // reusing contact cards (box)
            <Col className="box cyan">
                <h2>{name}</h2>
                <p>
                    {description}
                </p>
                <img className="team-image" src={"images/team/" + name.toLowerCase() + ".jpeg"}
                     alt={name}/>
            </Col>
        );
    }

    const [showConfetti, setShowConfetti] = React.useState(false);

    const toggleConfetti = () => {
        setShowConfetti(true);
    };

    return (
        <>
            <section className="about-us">
                <div className="overlay-content">
                    <h1 className="text-center main-heading">About Us</h1>
                </div>
            </section>

            <section className="why-us">
                <Container>
                    <h1 className="text-center main-heading left-heading">Why Us</h1>
                    <Row className="mt-5">
                        <Col className="sub-heading">
                            <p>THE IRIS, established in 2019 with the help of a grant from the GOVT. OF KARNATAKA is
                                into
                                the educational segment innovating to give your young one a good educational experience.
                                We believe that young minds can grow better when they use experience rather than words.
                                We
                                at THE IRIS are here to provide the 'clean slates' a sense of practicality through the
                                innovations we bring forward.</p>

                            <p>We aim to recognise difficulties of learning, and innovate solutions and provide children
                                with a fun filled learning experience. We are here to enrich the educational experience
                                of
                                children by providing them with an opportunity to have hands on learning.
                                We believe education is not just about academics.
                            </p>

                            <p>
                                Curiosity, Education and Creativity walk hand in hand and the former is strongly
                                associated with practicality.
                            </p>

                            <p>
                                We are here to shape young minds with innovations that quench their curiosity
                                through practical learning
                            </p>
                        </Col>
                    </Row>
                </Container>
            </section>

            <section className="who-are-we">
                <Container>
                    <h1 className="text-center main-heading left-heading">Who are we?</h1>
                    <Row className="mt-5">
                        <Col className="sub-heading">
                            {
                                teamCard(
                                    "S M Srinivas",
                                    "Mechanical Engineering Graduate from CMR Institute of Technology," +
                                    " Bangalore. Batch of 2018"
                                )
                            }
                        </Col>
                        <Col className="sub-heading">
                            {
                                teamCard(
                                    "Anandita Ghosh",
                                    "Electrical and Communication Engineering Graduate from CMR Institute" +
                                    " of Technology, Bangalore. Batch of 2018."
                                )
                            }
                        </Col>
                    </Row>
                </Container>
            </section>

            <ReactCanvasConfetti
                className="recognition-confetti"
                fire={showConfetti}
                onFire={() => setShowConfetti(false)}
                height={window.innerHeight}
            />

            <section className="recognition">
                <Container>
                    <h2 className="text-center text-black-50 left-heading">Recognition</h2>
                    <h1 className="text-center text-uppercase" onMouseEnter={() => toggleConfetti()}>Winner of
                        Elevate100, 2019</h1>

                    <Row className="mt-5">
                        <Col lg={4} className="sub-heading">
                            <p>
                                Recognized by Govt. of Karnataka for being among the top 100 most
                                innovative start-ups in Karnataka, an event organized by the Start-up cell
                                of Karnataka’s IT and BT Dept.
                            </p>
                        </Col>
                        <Col>
                            <img
                                src='images/team/elevate2019.jpg'
                                alt="Elevate 2019 Winners - The Iris"
                                className="img-fluid"/>
                        </Col>
                    </Row>
                </Container>
            </section>
        </>
    );
}

export default About;