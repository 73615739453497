import React from "react";
import "./navigation.css";
import {Container, Nav, Navbar} from "react-bootstrap";


function Navigation() {
    const [color, changeColor] = React.useState(false);

    const toggleColor = () => {
        if (window.scrollY > 10) {
            changeColor(true);
        } else {
            changeColor(false);
        }
    };
    window.addEventListener('scroll', toggleColor);

    return (
        <Navbar collapseOnSelect sticky={color ? "top" : ""}
                className={color ? "navbar-dark" : "navbar-light"}
                bg={color ? "dark" : "light"}
                expand="md">
            <Container>
                <Navbar.Brand href="/">
                    <img className="d-inline-block align-top"
                         src={color ? "/images/logowhite.png" : "/images/logo.png"}
                         alt="The Iris Logo"
                         width="100%" height="30"
                    />
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav"/>
                <Navbar.Collapse id="basic-navbar-nav" className="justify-content-end">
                    <Nav>
                        <Nav.Link href="/">Home</Nav.Link>
                        <Nav.Link href="/about">About</Nav.Link>
                        <Nav.Link href="/products">Products</Nav.Link>
                        <Nav.Link href="/contact">Contact</Nav.Link>
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    );
}

export default Navigation;